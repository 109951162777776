import style from '@/Widgets/StoryID/page.module.sass';
import { useRef, useState, useEffect, RefObject } from 'react';
import Image from 'next/image';

interface ArticlesMTCountersProps {
	StoryId: number;
}

export function ArticlesMTCounters({ StoryId }: ArticlesMTCountersProps) {
	const ArticlesStoryId = StoryId;

	const elComments: RefObject<HTMLDivElement> = useRef(null);
	const elLikes: RefObject<HTMLDivElement> = useRef(null);
	const elDislikes: RefObject<HTMLDivElement> = useRef(null);
	const elUserReaction: RefObject<HTMLDivElement> = useRef(null);

	const [likes, setLikes] = useState<number>(0);
	const [comments, setComments] = useState<number>(0);

	const id = `story-${ArticlesStoryId}`;
	const type = 'smi2';

	useEffect(() => {
		window.mtMessenger.push([
			'subscribeChatExternalMessageCount',
			{
				id,
				type,
				callbackFn: (value: string) => {
					if (elComments.current) {
						elComments.current.innerText = value;
					}
					setComments(parseInt(value, 10));
				}
			}
		]);

		window.mtMessenger.push([
			'subscribeChatExternalReactions',
			{
				id,
				type,
				callbackFn: ({
					reactions: { like, dislike },
					userReaction
				}: {
					reactions: { like: string; dislike: string };
					userReaction: string;
				}) => {
					if (elLikes.current) {
						elLikes.current.innerText = like;
					}
					if (elDislikes.current) {
						elDislikes.current.innerText = dislike;
					}
					if (elUserReaction.current) {
						elUserReaction.current.innerText = userReaction;
					}
					setLikes(parseInt(like, 10));
				}
			}
		]);

	}, [id, type]);

	return (
		<>
			{likes > 0 && (
				<>
					<Image width={8} height={16} src='/hot.svg' alt='hotNews' />
					<div ref={elLikes} className={style.Time}>
						{likes}
					</div>
				</>
			)}
			{comments > 0 && (
				<>
					<Image width={14} height={25} src='/Comment.svg' alt='Comments' />
					<div ref={elComments} className={style.Time}>
						{comments}
					</div>
				</>
			)}
		</>
	);
}
