import style from '@/Features/NewsComponents/news.module.sass';
import React, { useRef, useState, useEffect } from 'react';
import Link from 'next/link';

interface NewsCardMTButtonsCardProps {
	StoryId: number;
	Id: number;
	boxColor?: any;
}

interface ReactionData {
	like: number;
	dislike: number;
	userReaction: 'like' | 'dislike' | null;
}

export function NewsCardMTButtonsCard({
	StoryId,
	Id,
	boxColor
}: NewsCardMTButtonsCardProps) {
	const ArticlesStoryId = StoryId;

	const id = `story-${ArticlesStoryId}`;
	const type = 'smi2';

	const likeRef = useRef<boolean | null>(null);
	const likesCountRef = useRef<number>(0);
	const dislikesCountRef = useRef<number>(0);
	const [renderTrigger, setRenderTrigger] = useState<number>(0);

	useEffect(() => {
		const callbackFn = ({
			reactions: { like, dislike },
			userReaction
		}: {
			reactions: { like: number; dislike: number };
			userReaction: 'like' | 'dislike' | null;
		}) => {
			likesCountRef.current = like;
			dislikesCountRef.current = dislike;
			likeRef.current = userReaction === 'like';
			setRenderTrigger(rt => rt + 1);
		};

		window.mtMessenger.push([
			'subscribeChatExternalReactions',
			{
				id,
				type,
				callbackFn
			}
		]);

		return () => {
			window.mtMessenger.push([
				'unsubscribeChatExternalReactions',
				{ id, type }
			]);
		};
	}, [id, type]);

	const handleLikeClick = () => {
		const reactionName = likeRef.current ? null : 'like';
		window.mtMessenger.push([
			'setChatExternalReaction',
			{ id, type, reactionName }
		]);
	};

	return (
		<div className={style.optionsCardCont}>
			<Link
				className={style.Links}
				href={`/article/${Id}/story?id=comment-reply${StoryId}`}
			>
				<div className={style.commentBtnCard}>
					<svg
						width='17'
						height='15'
						viewBox='0 0 17 15'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M14.075 12.8024C11.6867 15.0948 8.15024 15.59 5.25619 14.3055C4.82895 14.1404 4.47868 14.007 4.14569 14.007C3.21818 14.0123 2.71729 15 0.757744 14.985C-1.41049 14.9684 1.7637 13.125 1.7637 11.7199C1.7637 11.4003 1.63018 11.0701 1.45819 10.6593C0.119153 7.88222 0.63592 4.48702 3.0242 2.1954C6.07296 -0.731676 11.0262 -0.731676 14.075 2.19465C17.1292 5.12625 17.1238 9.87607 14.075 12.8024Z'
							fill='#808080'
						/>
					</svg>
					Обсудить
				</div>
			</Link>
			<div
				className={style.hotNewsBtnCard}
				onClick={handleLikeClick}
				style={likeRef.current ? { background: '$OptionsButton-color' } : {}}
			>
				<svg
					className={likeRef.current ? style.GreenBtn : style.DefaultBtn}
					width='20'
					height='20'
					viewBox='0 0 20 20'
					fill={likeRef.current ? '#45AD4E' : ''}
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M13 4H8L6 10H9L7 17L14 8H10L13 4Z'
						stroke-width='2'
						stroke-linejoin='round'
					></path>
				</svg>
			</div>
		</div>
	);
}
