'use client';
import style from './news.module.sass';
import { useState, useEffect } from 'react';

interface NewNewsProps {
	newNewFunc: (value: boolean) => void;
	newNews: boolean;
	setClick: (value: boolean) => void;
	countNewNews: Array<number>;
}

export const NewNews = ({
	newNewFunc,
	newNews,
	setClick,
	countNewNews
}: NewNewsProps) => {
	const [isWindowScroll, setWindowScroll] = useState(0);
	const [randomNumber, setRandomNumber] = useState(0);

	const handlerScrollUp = () => {
		if (
			document.body.scrollTop > 500 ||
			document.documentElement.scrollTop > 500
		) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}
	};

	const handleScroll = () => {
		setWindowScroll(window.scrollY);
	};

	useEffect(() => {
		setRandomNumber(Random(4, 16));
	}, []);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	const Random = (from: number, to: number) => {
		from = Math.ceil(from);
		to = Math.floor(to);
		return Math.floor(Math.random() * (to - from + 1) + from);
	};

	return (
		<>
			{newNews ? (
				<div
					className={
						isWindowScroll > 400 ? style.Update : style.BtnScrollUpHide
					}
					onClick={() => {
						handlerScrollUp();
						newNewFunc(false);
						setRandomNumber(Random(4, 16));
						setClick(true);
					}}
				>
					<svg
						width='12'
						height='12'
						viewBox='0 0 14 14'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							fill='white'
							d='M0.332031 7.00004L1.50703 8.17504L6.16536 3.52504V13.6667H7.83203V3.52504L12.482 8.18337L13.6654 7.00004L6.9987 0.333374L0.332031 7.00004Z'
						></path>
					</svg>
					Показать новые новости
					{/* <span style={{ marginRight: '0px', marginLeft: '5px' }}>
						{countNewNews.length > 0 ? countNewNews.length : ''}
					</span> */}
				</div>
			) : (
				<></>
			)}
		</>
	);
};
